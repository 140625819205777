.tabs {
  margin-bottom: 1rem;
}

.tabs>li {
  cursor: pointer;
}

.eliminar {
  color: #dc3545;
}

.eliminar:hover {
  color: #c82333;
}

.config-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.config-list>li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  margin-bottom: 4px;
}

.config-list>li>img {
  max-height: 2.5rem;
}

.delete-config {
  float: right;
  padding-right: 0;
}

.lines-list {}

.lines-list>label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.9em;
  color: #2D2926;
}

.lines-list>ul {
  max-height: 57vh;
  overflow-y: scroll;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.lines-list>ul>li {
  padding: 0.25rem 0.5rem;
}

.lines-list>ul>li:nth-child(odd) {
  background-color: #f5f5f5;
}

.lines-list>ul>li>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lines-list>ul>li>div>span {
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
}

.label-routes {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.9em;
  color: #2D2926;
}

.lines-list>ul>li>div>div {
  display: flex;
  align-items: center;
}

.lines-list>ul>li>ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0.25rem 0 0.25rem 1.5rem;
  border-top: 1px solid #ccc;
}

.lines-list>ul>li>ul>li>a {
  color: #2D2926;
  display: block;
}

.lines-list>ul>li>ul>li>a:hover {
  color: #0056b3;
  text-decoration: none;
}

.separator {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 5px 15px 20px 15px;
}

.btn-sm-custom {
  padding: .15rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}